.range-slider {
  position: relative;
  margin: 0 10px;
  margin-top: 14px;
  opacity: 0.7;
  .slider {
    position: relative;
    z-index: 1;
    height: 4px;
   
    .track {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 8px;
      bottom: 0;
      border-radius: 5px;
      background: #ffffff71;
      opacity: 0.1;
    }
    // .range {
    //   position: absolute;
    //   z-index: 2;
    //   left: 25%;
    //   right: 25%;
    //   top: 0;
    //   bottom: 0;
    //   border-radius: 5px;
    //   background: #ffffff7a;
    //   opacity: 0.1;
    // }
    .thumb {
      position: absolute;
      z-index: 3;
      width: 24px;
      height: 24px;
      border: 0.75px solid #ff0018;
      background: #ffe5e8;
      border-radius: 50%;
    }
    .left_ {
      left: 0%;
      transform: translate(-15px, -10px);
    }
    .right_ {
      right: 25%;
      transform: translate(-10px, -10px);
    }
  }
  input[type="range"] {
    position: absolute;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    z-index: 2;
    // top: -50px;
    pointer-events: none;
    // opacity: 0;
    &::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      border: 0.75px solid #ff0018;
      background: #ffe5e8;
      -webkit-appearance: none;
      pointer-events: all;
      border-radius: 50%;
    }
    &::-webkit-slider-runnable-track {
      position: absolute;
      z-index: 1;
      left: -13px;
      right: -13px;
      top: -10px;
      bottom: 0;
      border-radius: 5px;
      opacity: 0;
    }
  }
}

