@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
.red_btn_shadow {
  box-shadow: 0px 15px 30px rgba(255, 0, 24, 0.16);
  transition: all;
  transition-duration: 0.5s;
  /* color: #d9d9d934; */
  
}

.red_btn_shadow:hover {
  background: #ad0010;
}

.transparent_bg {
  background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );
}
.transparent_cat_bg {
  background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );
  opacity: 0.7;
}
.transparent_cat_bg:hover {
  /* background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  ); */
  opacity: 1;
}

.transparent_bg2 {
  background: linear-gradient(
    97.57deg,
    rgba(255, 255, 255, 0.1) -0.01%,
    rgba(255, 255, 255, 0.025) 100.02%
  );
}
.transparent_bg3 {
  background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );
}
.transparent_bg4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(
      129.08deg,
      rgba(255, 255, 255, 0.1) -0.89%,
      rgba(255, 255, 255, 0.05) 100%
    );
}
.transparent_bg5 {
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );

  backdrop-filter: blur(20px);
}
.product_bg {
  background: linear-gradient(
    95deg,
    rgba(0, 0, 0, 0.5) 0.01%,
    rgba(0, 0, 0, 0.55) 100%
  );

  backdrop-filter: blur(20px);
}
.swiper-pagination {
  bottom: 300px !important;
  z-index: 9999 !important;
}
.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 0.3 !important;
}
.swiper-pagination-bullet-active {
  background-color: #ad0010 !important;
  opacity: 0.7 !important;
}
/* .image {
  background-image: url();
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
} */
.transparent_activebg {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(
      97.37deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
  border-left: 4px solid #ad0010;
  backdrop-filter: blur(20px);
}
.transparentBtn {
  background: linear-gradient(
    149.33deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100.04%
  );
  backdrop-filter: blur(21.3442px);
}
.transparent_hoverbg:hover {
  background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );
  border-left: 4px solid #ad0010;
  backdrop-filter: blur(20px);
}
.rider_page {
  background-image: url(./assets/images/badge.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.productCard {
  background: linear-gradient(
    149.33deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.55) 100.04%
  );
  box-shadow: 0px 11.7133px 23.4265px rgba(0, 0, 0, 0.16);
}
.locationCard {
  background: linear-gradient(
    149.33deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 100.04%
  );
  box-shadow: 0px 16.0081px 32.0162px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(21.3442px);
  border-radius: 24px;
}

.light-shadow {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.16);
}
.light-shadow2 {
  box-shadow: 4px 4px 16px #0000000d;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.auth_background {
  background-image: url(./assets/images/auth.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
}

.transparent_btn {
  background: linear-gradient(
    94.67deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0.05) 100%
  );
  cursor: pointer;
}
.transparent_btn:hover {
  background: #ad0010;
  transition: all ease-in-out 300ms;
}
.swiper-custom-pagination {
  position: absolute !important;
  bottom: 300px !important;
  left: 47% !important;
}
@media screen and (max-width: 830px) {
  .swiper-custom-pagination {
    position: absolute !important;
    bottom: 200px !important;
    left: 47% !important;
  }
}
@media screen and (max-width: 430px) {
  /*  */
  .auth_background {
    background-image: none;
    background-color: #171717;
  }
  .swiper-custom-pagination {
    position: absolute !important;
    bottom: 10px !important;
    left: 47% !important;
  }
}
::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
  background: rgba(133, 133, 133, 0.295);
}
::-webkit-scrollbar:hover {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: lightgray;
}
::-webkit-scrollbar-thumb {
  background: rgba(133, 133, 133, 0.8);
  width: 0.5em;
  height: 0.5em;
  border-radius: 1rem;
}
.text-area::-webkit-scrollbar {
  display: none;
}
.newscroll::-webkit-scrollbar {
  display: block;
}
.dotted_text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tst {
  color: #ffffff80;
  color: #f7f7f7f8;
  background: #ffffff17;
  background: #ffffff7e;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  color: #fafafaf5;
  color: #e78c9549;
  /* transition: trans; */
  transform: translateX(300);
}
